.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  max-width: 1440px;
  margin: 0 auto 30px;
  z-index: 999;

  @include desktop {
    margin-bottom: 35px;
    height: max-content;
    padding: 25px 40px 0;
  }

  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: transparent;
    visibility: visible;
    backdrop-filter: blur(0px);

    &.show {
      background: rgba(0, 0, 0, 0.92);
      backdrop-filter: blur(5px);
    }

    @include desktop {
      flex-direction: row;
    }
  }

  &__left {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 20px;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(5px);
    transition: all 0.3s ease-in-out;

    &.active {
      background: rgba(0, 0, 0, 0.92);
      backdrop-filter: blur(5px);
    }

    @include desktop {
      padding: 0;
      flex-direction: row;
      background: transparent;

      &.active {
        background: transparent;
      }
    }
  }

  &__right {
    display: none;

    @include desktop {
      display: block;
    }
  }
}

#eMx5icdhI123_to {
  animation: eMx5icdhI123_to__to 6600ms linear infinite normal forwards;
}

@keyframes eMx5icdhI123_to__to {
  0% {
    transform: translate(601.169434px, 337.5px);
    animation-timing-function: cubic-bezier(0.1592, 0.015, 0.7215, 0.5497);
  }
  33.333333% {
    transform: translate(601.142456px, 337.5px);
    animation-timing-function: cubic-bezier(0.1592, 0.015, 0.725, 0.9597);
  }
  78.787879% {
    transform: translate(601.142456px, 3878.473326px);
    animation-timing-function: cubic-bezier(0, 0.15, 1, 1);
  }
  81.818182% {
    transform: translate(601.142456px, 3855.473326px);
    animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  87.878788% {
    transform: translate(601.142456px, 3864.5px);
  }
  100% {
    transform: translate(601.142456px, 3864.5px);
  }
}