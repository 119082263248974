.contacts {
  position: relative;
  min-height: 100vh;
  height: max-content;
  background-image: url('./images/background_contacts.webp');
  background-size: cover  ;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 85px;

  @include desktop {
    background-position: left;
    padding-top: 120px;
  }

  .point {
    position: relative;
    width: 27px;
    height: 35px;
    background-image: url('./images/icons/map_point.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    &__text {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 12px;
      line-height: 120%;
    }

    &__wrapper {
      position: absolute;
      bottom: 20vh;
      left: 50%;
      transform: translateX(-50%);
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 9px;
      justify-items: center;

      @include desktop {
        left: 28vw;
      };
    }

    .circle {
      position: absolute;
      top: 7px;
      left: 28%;
      transform: translateX(-50%);
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: $color-yellow;
      animation: pulsar 5s infinite;
    }

    .elips-first {
      position: absolute;
      bottom: -2px;
      left: 43%;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.46);
      animation: elips 5s infinite;
    }

    .elips-second {
      position: absolute;
      bottom: -2px;
      left: 43%;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.3);
      animation: elipsS 5s infinite;
    }
  }

  &__content {
    position: relative;

    @include desktop {
      padding: 0 120px 160px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;

    @include desktop {
      flex-direction: row;
      justify-content: space-between;
    }

    &-links {
      margin-bottom: 30px;

      @include desktop {
        margin: 0;
      }

      & h2 {
        font-weight: 800;
        font-size: 28px;
        line-height: 120%;
        text-align: center;
        text-transform: uppercase;
        color: $color-white;
        margin-bottom: 30px;

        @include desktop {
          text-align: start;
          font-size: 44px;
          margin-bottom: 50px;
        }
      }

      .links__list {
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
        justify-items: center;
        grid-gap: 20px;

        @include desktop {
          justify-items: start;
          grid-gap: 30px;
        }

        &-link {
          display: grid;
          grid-template-columns: max-content max-content;
          align-items: center;
          grid-gap: 12px;
          font-weight: 500;
          font-size: 15px;
          line-height: 126%;
          color: $color-white;
        }

        &-image {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    &-message {
      display: none;

      @include desktop {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .message-card {
        position: relative;
        max-width: 480px;
        width: 100vw;
        padding: 32px 20px;
        margin: 0 -20px;
        background: rgba(255, 255, 255, 0.03);
        backdrop-filter: blur(8px);
        border-radius: 0;
        text-align: center;

        &::before {
          content: "";
          display: none;
          position: absolute;
          top: -20px;
          left: 0;
          right: 20%;
          height: 17px;
          background-image: url('./images/icons/top_line_contacts.svg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;

          @include desktop {
            display: block;
          }
        }

        &::after {
          content: "";
          display: none;
          position: absolute;
          bottom: -36px;
          left: 50%;
          right: 0;
          height: 36px;
          background-image: url('./images/icons/bottom_line_contacts.svg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;

          @include desktop {
            display: block;
          }
        }

        @include desktop {
          padding: 56px 64px;
          border: 1px solid rgba(255, 255, 255, 0.25);
          border-radius: 0px 100px;
        }

        & h3 {
          font-weight: 600;
          font-size: 18px;
          line-height: 120%;
          text-align: center;
          text-transform: uppercase;
          color: $color-white;
          margin-bottom: 30px;

          @include desktop {
            font-size: 24px;
            margin-bottom: 50px;
          }
        }
      }

      .message-input {
        font-weight: 500;
        font-size: 14px;
        line-height: 126%;
        color: $color-white;
        box-sizing: border-box;
        padding: 14px 16px;
        width: 100%;
        max-height: 46px;
        margin-bottom: 14px;
        background: #1C1E21;
        border: 1px solid #1C1E21;
        border-radius: 10px;
        resize: none;

        &:focus {
          border: 1px solid $color-light-gray;
        }

        &::placeholder {
          font-weight: 500;
          font-size: 14px;
          line-height: 126%;
          color: $color-gray;
        }
      }

      .message-textarea {
        font-family: "Popins", sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 126%;
        color: $color-white;
        box-sizing: border-box;
        padding: 14px 16px;
        width: 100%;
        height: 100px;
        background: #1C1E21;
        border: 1px solid #1C1E21;
        border-radius: 10px;
        outline: none;
        margin-bottom: 30px;

        &:focus {
          border: 1px solid $color-light-gray;
        }

        &::placeholder {
          font-family: "Popins", sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 126%;
          color: $color-gray;
        }
      }
    }
  }
}

@keyframes pulsar {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes elips {
  0% {
    transform: scaleX(1) scaleY(1);
  }
  50% {
    transform: scaleX(6) scaleY(1.5);
  }
  100% {
    transform: scaleX(1) scaleY(1);
  }
}

@keyframes elipsS {
  0% {
    transform: scaleX(1) scaleY(1);
  }
  50% {
    transform: scaleX(10) scaleY(3);
  }
  100% {
    transform: scaleX(1) scaleY(1);
  }
}
