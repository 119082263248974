.deals {
  position: relative;
  min-height: 100vh;
  height: max-content;
  background-image: url('./images/background_deals_mobile.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding-top: 85px;

  @include desktop {
    background-image: url('./images/background_deals.webp');
    padding-top: 100px;
  }

  &__content {
    padding: 0 0 40px;

    @include desktop {
      padding: 0 30px 0 60px;
    }

    & h2 {
      font-weight: 800;
      font-size: 28px;
      line-height: 120%;
      text-align: center;
      text-transform: uppercase;
      color: $color-white;
      margin-bottom: 20px;

      @include desktop {
        font-size: 44px;
      }
    }

    & p {
      font-weight: 400;
      font-size: 13px;
      line-height: 168%;
      color: $color-light-gray;
      text-align: center;
      margin-bottom: 50px;

      @include desktop {
        margin-bottom: 80px;
      }
    }
  }
}

.card {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  height: max-content;
  padding: 12px 16px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(4px);

  @include  desktop {
    &:nth-child(even) {
      transform: translateY(25%);
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: -20px;
    left: -15px;
    right: -15px;
    height: 11px;
    background-image: url('./images/icons/top_line.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -30px;
    left: -15px;
    right: -15px;
    height: 22px;
    background-image: url('./images/icons/bottom_line.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__wrapper {
    overflow-x: auto;
    padding: 30px 40px 60px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 60px;
    align-items: center;
    margin-bottom: 66px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @include desktop {
      padding: 30px 10px 80px;
      grid-gap: 30px;
      overflow-x: hidden;
      margin-bottom: 150px;
    }
  }

  &__info {
    padding: 26px 6px;
    border-top: 1px solid rgba(0, 0, 0, 0.5);
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);

    & h4 {
      font-weight: 500;
      font-size: 22px;
      line-height: 120%;
      text-align: center;
      text-transform: uppercase;
      color: $color-white;
      margin-bottom: 4px;
      white-space: nowrap;
    }

    & h3 {
      font-weight: 800;
      font-size: 26px;
      line-height: 120%;
      text-align: center;
      text-transform: uppercase;
      color: $color-yellow;
      margin-bottom: 26px;
    }

    &-list {
      display: grid;
      grid-template-rows: max-content max-content;
      justify-items: center;
      grid-gap: 12px;

      &-item {
        font-weight: 400;
        font-size: 15px;
        line-height: 25px;
        color: $color-white;
        white-space: nowrap;
      }
    }
  }
}

.link {
  text-align: center;
  padding-bottom: 100px;

  @include desktop {
    padding-bottom: 110px;
  }
}
