.scroll {
  position: absolute;
  bottom: 65px;
  right: 20px;
  display: flex;
  justify-content: flex-end;
  z-index: 100;

  @include  desktop {
    bottom: 55px;
    right: 40px;
  }

  &__image {
    & img {
      width: 10px;
      height: 25px;

      @include  desktop {
        width: 20px;
        height: 30px;
      }
    }
  }

  &__text {
    text-align: end;
    margin-right: 8px;

    @include desktop {
      margin-right: 16px;
    }

    & h4 {
      font-weight: 600;
      font-size: 11px;
      line-height: 120%;
      color: $color-white;

      @include desktop {
        font-size: 14px;
      }
    }

    & p {
      font-weight: 400;
      font-size: 10px;
      line-height: 120%;
      color: $color-light-gray;

      @include desktop {
        font-size: 12px;
      }
    }
  }
}
