.general {
  position: relative;
  min-height: 100vh;
  height: max-content;
  background-image: url('./images/background_general.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 85px;
  overflow: hidden;

  @include desktop {
    padding-top: 100px;
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -85%);
    text-align: center;

    & h1 {
      max-width: 715px;
      width: 320px;
      margin: 0 auto;
      text-align: center;
      font-weight: 700;
      font-size: 52px;
      line-height: 100%;
      color: $color-dark;
      text-transform: uppercase;

      @include desktop {
        width: 100%;
        font-size: 89px;
      }
    }

    & h3 {
      font-weight: 500;
      font-size: 15px;
      line-height: 120%;
      color: $color-dark;
      text-transform: uppercase;

      @include desktop {
        font-size: 22px;
      }
    }
  }

  &__effect {
    max-width: 930px;
    width: 100vw;
    height: 21px;
    margin: 0 auto;
    background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.74) 0%, rgba(255, 255, 255, 0.24) 59.32%, rgba(255, 255, 255, 0) 100%);
  }
}

.cosmonaut {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 40vh;
  background-image: url("./images/cosmonaut.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  z-index: 10;

  @include desktop {
    height: 45vh;
    background-size: contain;
  }
}

$point-big: calc(50% - 127px/2);
$pont-middle: calc(50% - 57px/2);
$pont-small: calc(50% - 32px/2);

.planet {
  position: absolute;
  background-image: url("./images/planet.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  &.big {
    top: $point-big;
    right: $point-big;
    width: 127px;
    height: 127px;
    animation: moveCircleBig 30s infinite linear;
  }

  &.middle {
    top: $pont-middle;
    right: $pont-middle;
    width: 57px;
    height: 57px;
    animation: moveCircleMiddle 45s infinite linear;
  }

  &.small {
    top:  $pont-small;
    right: $pont-small;
    width: 32px;
    height: 32px;
    animation: moveCircleSmall 60s infinite linear;
  }
}

@keyframes moveCircleBig {
  0% {
    transform: rotate(0deg) translate3d(0px, -40vh, 0px) rotate(0deg);
  }

  100% {
    transform: rotate(360deg) translate3d(0px, -40vh, 0px) rotate(-360deg);
  }
}

@keyframes moveCircleMiddle {
  0% {
    transform: rotate(0deg) translate3d(0px, 60vh, 0px) rotate(0deg);
  }

  100% {
    transform: rotate(360deg) translate3d(0px, 60vh, 0px) rotate(-360deg);
  }
}

@keyframes moveCircleSmall {
  0% {
    transform: rotate(0deg) translate3d(50vh, 0px, 0px) rotate(0deg);
  }

  100% {
    transform: rotate(360deg) translate3d(50vh, 0px, 0px) rotate(-360deg);
  }
}
