p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
  padding: 0;
  margin: 0;
}

* {
  padding: 0;
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  display: inline-block;
  text-decoration: none;
}

ul, ol {
  text-decoration: none;
  list-style-type: none;
  list-style: none;
  text-decoration-line: none;
}

input {
  outline: none;
}
