.about {
  position: relative;
  min-height: 100vh;
  height: max-content;
  width: 100%;
  margin: 0 auto;
  max-width: 1440px;

  @include desktop {
    padding-top: 100px;
  }

  &__content {
    & p {
      z-index: 10;
      position: absolute;
      top: 85px;
      left: 0;
      right: 0;
      padding: 0 20px;
      max-width: 1046px;
      margin: 0 auto;
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 168%;
      color: $color-light-gray;

      @include desktop {
        top: 125px;
        margin-bottom: 60px;
        font-size: 16px;
      }
    }
  }

  &__bg {
    padding-top: 85px;
    overflow-y: visible;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @include desktop {
      padding: 0;
    }

    &-scroll {
      position: relative;
      min-width: max-content;
      width: 100%;

      @include desktop {
        min-width: auto;
        margin: 0 auto;
        width: 100%;
      }

      .about__bg-image {
        object-fit: cover;
        height: 90vh;

        @include desktop {
          object-position: bottom;
          width: 100%;
        }
      }

      .note {
        position: absolute;
        bottom: 52%;

        &:nth-child(1) {
          left: 5%;
        }
        &:nth-child(2) {
          left: 22%;
        }
        &:nth-child(3) {
          left: 42%;
        }
        &:nth-child(4) {
          left: 63%;
        }
        &:nth-child(5) {
          left: 81%;
        }

        @include desktop {
          &:nth-child(1) {
            bottom: 53%;
            left: 24%;

            .note__line {
              height: 13vh;
            }

            .note__bot-dot {
              bottom: -11vh;
            }
          }

          &:nth-child(2) {
            bottom: 58%;
            left: 36%;

            .note__line {
              height: 18vh;
            }

            .note__bot-dot {
              bottom: -16vh;
            }
          }
          &:nth-child(3) {
            bottom: 62%;
            left: 50%;

            .note__line {
              height: 21vh;
            }

            .note__bot-dot {
              bottom: -19vh;
            }
          }
          &:nth-child(4) {
            bottom: 58%;
            left: 62%;

            .note__line {
              height: 18vh;
            }

            .note__bot-dot {
              bottom: -16vh;
            }
          }

          &:nth-child(5) {
            bottom: 53%;
            left: 74%;

            .note__line {
              height: 13vh;
            }

            .note__bot-dot {
              bottom: -11vh;
            }
          }
        }

        &__title {
          font-weight: 500;
          font-size: 13px;
          line-height: 144%;
          text-align: center;
          color: $color-white;

          @include  desktop {
            text-align: start;
            font-size: 16px;
          }
        }

        &__up-dot {
          position: absolute;
          left: calc(50% - 2px);
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: $color-white;

          @include  desktop {
            left: -16px;
            top: 50%;
          }
        }

        &__line {
          position: absolute;
          left: calc(50% - 1px);
          width: 2px;
          height: 65px;
          background-color: $color-white;

          @include  desktop {
            top: 50%;
            left: -15px;
          }
        }

        &__bot-dot {
          position: absolute;
          left: calc(50% - 6px);
          bottom: -75px;
          width: 12px;
          height: 12px;
          border-radius: 50%;

          @include  desktop {
            left: -20px;
          }

          .lap {
                position: absolute;
                border-radius: 50%;

                &.first {
                  background-color: $color-white;
                  width: 12px;
                  height: 12px;
                }

                &.second {
                  width: 8px;
                  height: 8px;
                  top: calc(50% - 4px);
                  left: calc(50% - 4px);
                  animation: lapSecond 6s infinite linear;
                }

                &.third {
                  width: 4px;
                  height: 4px;
                  top: calc(50% - 2px);
                  left: calc(50% - 2px);
                  animation: lapThird 6s infinite linear;
                }
              }
        }
      }
    }
  }
}

@keyframes lapSecond {
  0% {
    transform: scale(1);
    background-color: $color-yellow;
  }

  50% {
    transform: scale(4);
    background: rgba(252, 238, 32, 0.2);
  }
  100% {
    transform: scale(1);
    background-color: $color-yellow;
  }
}

@keyframes lapThird {
  0% {
    transform: scale(1);
    background-color: transparent;
  }

  50% {
    transform: scale(5);
    background: rgba(252, 238, 32, 0.46);
  }
  100% {
    transform: scale(1);
    background-color: transparent;
  }
}
