.logo {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease-in-out;
  
  @include desktop {
    &.hidden {
      opacity: 0;
      visibility: hidden;
    }
  }

  & a img,
  & a svg {
    width: 112px;
    height: 36px;
  }
}

.logo__svg__mask{
  transform: translate(-601.142456px, -150%);
}
