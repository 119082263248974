.primary-button {
  @include text-button;

  align-items: center;
  text-align: center;
  color: $color-black;
  background-color: $color-yellow;
  border-radius: 0px;
  transition: all 0.3s ease-in-out;

  @include  desktop {
    border-radius: 12px;
  }

  &:hover,
  &.active {
    background-color: $color-white;
  }

  &:disabled,
  &[disabled] {
    opacity: 0.5;
  }

  &.green {
    background-color: $color-green;
  }
}
