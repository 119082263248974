.sidebar {
  display: none;
  position: fixed;
  top: 10%;
  bottom: 10%;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  z-index: 100;

  @include desktop {
    display: block;
  
    &__list {
      display: flex;
      justify-content: space-around;
      align-items: center;
  
      &-link {
        position: relative;
        font-weight: 600;
        font-size: 26px;
        line-height: 120%;
        text-transform: uppercase;
        color: $color-white;

        &.active {
          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 2px;
            height: 36px;
            background-color: $color-yellow;
          }
        }
      }
    }
  }
  
}