@mixin desktop {
  @media (min-width: 1180px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 734px) and (max-width: 1180px) {
    @content;
  }
}

@mixin text-button {
  font-weight: 600;
  font-size: 14px;
  line-height: 126%;
  padding: 14px 30px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  white-space: nowrap;

  @include desktop {
    border-radius: 12px;
  }
}
