.pagination {
  display: none;
  
  @include desktop {
    display: block;
    position: absolute;
    top: 50vh;
    transform: translateY(-50%);
    right: 40px;
    z-index: 99;

    &::after,
    &::before {
      content: "";
      position: absolute;
      width: 1px;
      height: 40px;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 12px;
      left: 50%;
      transform: translateX(-50%);
    }

    &::after {
      bottom: -65px;
    }

    &::before {
      top: -65px;
    }
  }

  &__content {
    display: grid;
    grid-template-rows: repeat(6, 1fr);
    grid-gap: 20px;
  }

  .dot {
    position: relative;
    width: 10px;
    height: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    box-sizing: border-box;
    cursor: pointer;
  
    &.active {
      width: 8px;
      height: 8px;
      border: 1px solid $color-white;
      background: $color-white;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 18px;
        height: 18px;
        box-sizing: border-box;
        border-radius: 50%;
        border: 1px solid #FCEE20;
        filter: drop-shadow(0px 0px 27px #FCEE20) drop-shadow(0px 0px 12px #FCEE20);
      }
    }
  }
}