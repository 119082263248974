p, h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
  padding: 0;
}

* {
  margin: 0;
  padding: 0;
}

*, :before, :after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  display: inline-block;
}

ul, ol {
  text-decoration: none;
  list-style: none;
}

input {
  outline: none;
}

html, body {
  color: #fff;
  box-sizing: border-box;
  background-color: #070707;
  font-family: Poppins, sans-serif;
  overflow-x: hidden;
}

html::-webkit-scrollbar, body::-webkit-scrollbar {
  display: none;
}

.toastify {
  color: #fff;
  opacity: 0;
  cursor: pointer;
  max-width: calc(50% - 20px);
  z-index: 2147483647;
  background: linear-gradient(135deg, #73a5ff, #5477f5);
  border-radius: 2px;
  padding: 12px 20px;
  text-decoration: none;
  transition: all .4s cubic-bezier(.215, .61, .355, 1);
  display: inline-block;
  position: fixed;
  box-shadow: 0 3px 6px -1px #0000001f, 0 10px 36px -4px #4d60e84d;
}

.toastify.on {
  opacity: 1;
}

.toast-close {
  color: #fff;
  cursor: pointer;
  opacity: .4;
  background: none;
  border: 0;
  padding: 0 5px;
  font-family: inherit;
  font-size: 1em;
}

.toastify-right {
  right: 15px;
}

.toastify-left {
  left: 15px;
}

.toastify-top {
  top: -150px;
}

.toastify-bottom {
  bottom: -150px;
}

.toastify-rounded {
  border-radius: 25px;
}

.toastify-avatar {
  width: 1.5em;
  height: 1.5em;
  border-radius: 2px;
  margin: -7px 5px;
}

.toastify-center {
  max-width: -moz-fit-content;
  max-width: fit-content;
  max-width: -moz-fit-content;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

@media only screen and (width <= 360px) {
  .toastify-right, .toastify-left {
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
}

.wrapper {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;
}

@media (width >= 1180px) {
  .wrapper {
    padding: 0 40px;
  }
}

.wrapper--scroll {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0;
}

@media (width >= 1180px) {
  .wrapper--scroll {
    padding: 0 40px;
  }

  .wrapper--scroll.pad {
    padding: 0;
  }
}

.scroll {
  z-index: 100;
  justify-content: flex-end;
  display: flex;
  position: absolute;
  bottom: 65px;
  right: 20px;
}

@media (width >= 1180px) {
  .scroll {
    bottom: 55px;
    right: 40px;
  }
}

.scroll__image img {
  width: 10px;
  height: 25px;
}

@media (width >= 1180px) {
  .scroll__image img {
    width: 20px;
    height: 30px;
  }
}

.scroll__text {
  text-align: end;
  margin-right: 8px;
}

@media (width >= 1180px) {
  .scroll__text {
    margin-right: 16px;
  }
}

.scroll__text h4 {
  color: #fff;
  font-size: 11px;
  font-weight: 600;
  line-height: 120%;
}

@media (width >= 1180px) {
  .scroll__text h4 {
    font-size: 14px;
  }
}

.scroll__text p {
  color: #a4a9b1;
  font-size: 10px;
  font-weight: 400;
  line-height: 120%;
}

@media (width >= 1180px) {
  .scroll__text p {
    font-size: 12px;
  }
}

.header {
  max-width: 1440px;
  z-index: 999;
  margin: 0 auto 30px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

@media (width >= 1180px) {
  .header {
    height: max-content;
    margin-bottom: 35px;
    padding: 25px 40px 0;
  }
}

.header__content {
  height: 100%;
  visibility: visible;
  -webkit-backdrop-filter: blur();
  backdrop-filter: blur();
  background: none;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.header__content.show {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background: #000000eb;
}

@media (width >= 1180px) {
  .header__content {
    flex-direction: row;
  }
}

.header__left {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background: #0006;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  transition: all .3s ease-in-out;
  display: flex;
}

.header__left.active {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background: #000000eb;
}

@media (width >= 1180px) {
  .header__left {
    background: none;
    flex-direction: row;
    padding: 0;
  }

  .header__left.active {
    background: none;
  }
}

.header__right {
  display: none;
}

@media (width >= 1180px) {
  .header__right {
    display: block;
  }
}

#eMx5icdhI123_to {
  animation: 6.6s linear infinite forwards eMx5icdhI123_to__to;
}

@keyframes eMx5icdhI123_to__to {
  0% {
    animation-timing-function: cubic-bezier(.1592, .015, .7215, .5497);
    transform: translate(601.169px, 337.5px);
  }

  33.3333% {
    animation-timing-function: cubic-bezier(.1592, .015, .725, .9597);
    transform: translate(601.142px, 337.5px);
  }

  78.7879% {
    animation-timing-function: cubic-bezier(0, .15, 1, 1);
    transform: translate(601.142px, 3878.47px);
  }

  81.8182% {
    animation-timing-function: cubic-bezier(.39, .575, .565, 1);
    transform: translate(601.142px, 3855.47px);
  }

  87.8788% {
    transform: translate(601.142px, 3864.5px);
  }

  100% {
    transform: translate(601.142px, 3864.5px);
  }
}

.menu {
  width: max-content;
  margin: 0;
}

@media (width >= 1180px) {
  .menu {
    margin: 0 48px 0 0;
  }
}

.menu-button {
  width: 32px;
  height: 22px;
  cursor: pointer;
  background: url("menu.d393ddba.svg") center no-repeat;
  border: none;
  outline: none;
  transition: all .3s ease-in-out;
}

.menu-button.close {
  background-image: url("cross.c4ad0251.svg");
}

.menu__content {
  height: 100vh;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background: #000000eb;
  transition: all .3s ease-in-out;
  position: fixed;
  top: 82px;
  left: 0;
  right: 0;
  transform: translateX(100%);
}

.menu__content.show {
  transform: translate(0);
}

@media (width >= 1180px) {
  .menu__content.show {
    transform: translate(0, -10%);
  }

  .menu__content {
    width: max-content;
    height: max-content;
    -webkit-backdrop-filter: blur();
    backdrop-filter: blur();
    opacity: 0;
    visibility: hidden;
    background: none;
    padding: 0;
    top: 0;
    left: 30%;
    transform: translate(50%, -10%);
  }

  .menu__content.show {
    opacity: 1;
    visibility: visible;
  }
}

.menu__list {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (width >= 1180px) {
  .menu__list {
    flex-direction: row;
  }

  .menu__list-item:nth-child(2), .menu__list-item:nth-child(3), .menu__list-item:nth-child(4) {
    display: none;
  }
}

.menu__list-link {
  white-space: nowrap;
  color: #fff;
  text-transform: uppercase;
  padding: 15px 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 126%;
}

.logo {
  opacity: 1;
  visibility: visible;
  transition: all .3s ease-in-out;
}

@media (width >= 1180px) {
  .logo.hidden {
    opacity: 0;
    visibility: hidden;
  }
}

.logo a img, .logo a svg {
  width: 112px;
  height: 36px;
}

.logo__svg__mask {
  transform: translate(-601.142px, -150%);
}

.buttons {
  grid-gap: 1px;
  grid-template-columns: 1fr 1fr;
  display: grid;
}

@media (width >= 1180px) {
  .buttons {
    grid-gap: 15px;
  }
}

.buttons button {
  border-radius: 0;
}

@media (width >= 1180px) {
  .buttons button {
    border-radius: 12px;
  }
}

.buttons__container {
  z-index: 100;
  max-width: 1440px;
  margin: 0 auto;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (width >= 1180px) {
  .buttons__container .buttons {
    display: none;
  }
}

.primary-button {
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  text-align: center;
  color: #000;
  background-color: #fcee20;
  border: none;
  border-radius: 0;
  align-items: center;
  padding: 14px 30px;
  font-size: 14px;
  font-weight: 600;
  line-height: 126%;
  transition: all .3s ease-in-out;
}

@media (width >= 1180px) {
  .primary-button {
    border-radius: 12px;
  }
}

.primary-button:hover, .primary-button.active {
  background-color: #fff;
}

.primary-button:disabled, .primary-button[disabled] {
  opacity: .5;
}

.primary-button.green {
  background-color: #35ec94;
}

.secondary-button {
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  text-align: center;
  color: #fff;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background: #0000002e;
  border: none;
  align-items: center;
  padding: 14px 30px;
  font-size: 14px;
  font-weight: 600;
  line-height: 126%;
  transition: all .3s ease-in-out;
}

@media (width >= 1180px) {
  .secondary-button {
    border-radius: 12px;
  }
}

.secondary-button:hover, .secondary-button.active {
  background-color: #62666c;
}

.secondary-button:disabled, .secondary-button[disabled] {
  opacity: .5;
}

.secondary-button.second {
  border: 1px solid #62666c;
  border-radius: 12px;
}

@media (width >= 1180px) {
  .secondary-button {
    background-color: #0000;
  }
}

.sidebar {
  writing-mode: vertical-rl;
  z-index: 100;
  display: none;
  position: fixed;
  top: 10%;
  bottom: 10%;
  transform: rotate(180deg);
}

@media (width >= 1180px) {
  .sidebar {
    display: block;
  }

  .sidebar__list {
    justify-content: space-around;
    align-items: center;
    display: flex;
  }

  .sidebar__list-link {
    text-transform: uppercase;
    color: #fff;
    font-size: 26px;
    font-weight: 600;
    line-height: 120%;
    position: relative;
  }

  .sidebar__list-link.active:after {
    content: "";
    width: 2px;
    height: 36px;
    background-color: #fcee20;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.general {
  min-height: 100vh;
  height: max-content;
  background-image: url("background_general.2c9fa462.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 85px;
  position: relative;
  overflow: hidden;
}

@media (width >= 1180px) {
  .general {
    padding-top: 100px;
  }
}

.general__content {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -85%);
}

.general__content h1 {
  max-width: 715px;
  width: 320px;
  text-align: center;
  color: #070707;
  text-transform: uppercase;
  margin: 0 auto;
  font-size: 52px;
  font-weight: 700;
  line-height: 100%;
}

@media (width >= 1180px) {
  .general__content h1 {
    width: 100%;
    font-size: 89px;
  }
}

.general__content h3 {
  color: #070707;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 500;
  line-height: 120%;
}

@media (width >= 1180px) {
  .general__content h3 {
    font-size: 22px;
  }
}

.general__effect {
  max-width: 930px;
  width: 100vw;
  height: 21px;
  background: radial-gradient(50% 50%, #ffffffbd 0%, #ffffff3d 59.32%, #fff0 100%);
  margin: 0 auto;
}

.cosmonaut {
  height: 40vh;
  z-index: 10;
  background-image: url("cosmonaut.69f95f11.webp");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (width >= 1180px) {
  .cosmonaut {
    height: 45vh;
    background-size: contain;
  }
}

.planet {
  background-image: url("planet.d07e7660.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
}

.planet.big {
  width: 127px;
  height: 127px;
  animation: 30s linear infinite moveCircleBig;
  top: calc(50% - 63.5px);
  right: calc(50% - 63.5px);
}

.planet.middle {
  width: 57px;
  height: 57px;
  animation: 45s linear infinite moveCircleMiddle;
  top: calc(50% - 28.5px);
  right: calc(50% - 28.5px);
}

.planet.small {
  width: 32px;
  height: 32px;
  animation: 60s linear infinite moveCircleSmall;
  top: calc(50% - 16px);
  right: calc(50% - 16px);
}

@keyframes moveCircleBig {
  0% {
    transform: rotate(0)translate3d(0, -40vh, 0)rotate(0);
  }

  100% {
    transform: rotate(360deg)translate3d(0, -40vh, 0)rotate(-360deg);
  }
}

@keyframes moveCircleMiddle {
  0% {
    transform: rotate(0)translate3d(0, 60vh, 0)rotate(0);
  }

  100% {
    transform: rotate(360deg)translate3d(0, 60vh, 0)rotate(-360deg);
  }
}

@keyframes moveCircleSmall {
  0% {
    transform: rotate(0)translate3d(50vh, 0, 0)rotate(0);
  }

  100% {
    transform: rotate(360deg)translate3d(50vh, 0, 0)rotate(-360deg);
  }
}

.about {
  min-height: 100vh;
  height: max-content;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
}

@media (width >= 1180px) {
  .about {
    padding-top: 100px;
  }
}

.about__content p {
  z-index: 10;
  max-width: 1046px;
  text-align: center;
  color: #a4a9b1;
  margin: 0 auto;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 168%;
  position: absolute;
  top: 85px;
  left: 0;
  right: 0;
}

@media (width >= 1180px) {
  .about__content p {
    margin-bottom: 60px;
    font-size: 16px;
    top: 125px;
  }
}

.about__bg {
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-top: 85px;
  overflow-x: auto;
  overflow-y: visible;
}

.about__bg::-webkit-scrollbar {
  display: none;
}

@media (width >= 1180px) {
  .about__bg {
    padding: 0;
  }
}

.about__bg-scroll {
  min-width: max-content;
  width: 100%;
  position: relative;
}

@media (width >= 1180px) {
  .about__bg-scroll {
    min-width: auto;
    width: 100%;
    margin: 0 auto;
  }
}

.about__bg-scroll .about__bg-image {
  object-fit: cover;
  height: 90vh;
}

@media (width >= 1180px) {
  .about__bg-scroll .about__bg-image {
    object-position: bottom;
    width: 100%;
  }
}

.about__bg-scroll .note {
  position: absolute;
  bottom: 52%;
}

.about__bg-scroll .note:first-child {
  left: 5%;
}

.about__bg-scroll .note:nth-child(2) {
  left: 22%;
}

.about__bg-scroll .note:nth-child(3) {
  left: 42%;
}

.about__bg-scroll .note:nth-child(4) {
  left: 63%;
}

.about__bg-scroll .note:nth-child(5) {
  left: 81%;
}

@media (width >= 1180px) {
  .about__bg-scroll .note:first-child {
    bottom: 53%;
    left: 24%;
  }

  .about__bg-scroll .note:first-child .note__line {
    height: 13vh;
  }

  .about__bg-scroll .note:first-child .note__bot-dot {
    bottom: -11vh;
  }

  .about__bg-scroll .note:nth-child(2) {
    bottom: 58%;
    left: 36%;
  }

  .about__bg-scroll .note:nth-child(2) .note__line {
    height: 18vh;
  }

  .about__bg-scroll .note:nth-child(2) .note__bot-dot {
    bottom: -16vh;
  }

  .about__bg-scroll .note:nth-child(3) {
    bottom: 62%;
    left: 50%;
  }

  .about__bg-scroll .note:nth-child(3) .note__line {
    height: 21vh;
  }

  .about__bg-scroll .note:nth-child(3) .note__bot-dot {
    bottom: -19vh;
  }

  .about__bg-scroll .note:nth-child(4) {
    bottom: 58%;
    left: 62%;
  }

  .about__bg-scroll .note:nth-child(4) .note__line {
    height: 18vh;
  }

  .about__bg-scroll .note:nth-child(4) .note__bot-dot {
    bottom: -16vh;
  }

  .about__bg-scroll .note:nth-child(5) {
    bottom: 53%;
    left: 74%;
  }

  .about__bg-scroll .note:nth-child(5) .note__line {
    height: 13vh;
  }

  .about__bg-scroll .note:nth-child(5) .note__bot-dot {
    bottom: -11vh;
  }
}

.about__bg-scroll .note__title {
  text-align: center;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  line-height: 144%;
}

@media (width >= 1180px) {
  .about__bg-scroll .note__title {
    text-align: start;
    font-size: 16px;
  }
}

.about__bg-scroll .note__up-dot {
  width: 4px;
  height: 4px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  left: calc(50% - 2px);
}

@media (width >= 1180px) {
  .about__bg-scroll .note__up-dot {
    top: 50%;
    left: -16px;
  }
}

.about__bg-scroll .note__line {
  width: 2px;
  height: 65px;
  background-color: #fff;
  position: absolute;
  left: calc(50% - 1px);
}

@media (width >= 1180px) {
  .about__bg-scroll .note__line {
    top: 50%;
    left: -15px;
  }
}

.about__bg-scroll .note__bot-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  bottom: -75px;
  left: calc(50% - 6px);
}

@media (width >= 1180px) {
  .about__bg-scroll .note__bot-dot {
    left: -20px;
  }
}

.about__bg-scroll .note__bot-dot .lap {
  border-radius: 50%;
  position: absolute;
}

.about__bg-scroll .note__bot-dot .lap.first {
  width: 12px;
  height: 12px;
  background-color: #fff;
}

.about__bg-scroll .note__bot-dot .lap.second {
  width: 8px;
  height: 8px;
  animation: 6s linear infinite lapSecond;
  top: calc(50% - 4px);
  left: calc(50% - 4px);
}

.about__bg-scroll .note__bot-dot .lap.third {
  width: 4px;
  height: 4px;
  animation: 6s linear infinite lapThird;
  top: calc(50% - 2px);
  left: calc(50% - 2px);
}

@keyframes lapSecond {
  0% {
    background-color: #fcee20;
    transform: scale(1);
  }

  50% {
    background: #fcee2033;
    transform: scale(4);
  }

  100% {
    background-color: #fcee20;
    transform: scale(1);
  }
}

@keyframes lapThird {
  0% {
    background-color: #0000;
    transform: scale(1);
  }

  50% {
    background: #fcee2075;
    transform: scale(5);
  }

  100% {
    background-color: #0000;
    transform: scale(1);
  }
}

.geo {
  min-height: 100vh;
  height: max-content;
  background-image: url("background_geo.71a260c3.webp");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 85px;
  position: relative;
}

@media (width >= 1180px) {
  .geo {
    padding-top: 100px;
  }
}

.geo__content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.geo__info {
  margin-bottom: 30px;
}

@media (width >= 1180px) {
  .geo__info {
    display: none;
  }
}

.geo__info h2 {
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 800;
  line-height: 120%;
}

.geo__list {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 16px;
  display: flex;
}

.geo__list-item {
  color: #fff;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  white-space: nowrap;
  background: #ffffff1f;
  border-radius: 10px;
  padding: 8px 14px;
  font-size: 12px;
  font-weight: 600;
  line-height: 152%;
}

.geo__map {
  display: none;
}

@media (width >= 1180px) {
  .geo__map {
    text-align: center;
    display: block;
  }

  .geo__map #geo-obj {
    width: 1100px;
    pointer-events: none;
  }
}

.geo__bg {
  width: 100%;
  height: 40vh;
  position: relative;
}

@media (width >= 1180px) {
  .geo__bg {
    display: none;
  }
}

.geo__bg .openseadragon-canvas {
  outline: none !important;
}

.geo__bg .zoom {
  align-items: center;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

.geo__bg .zoom img {
  width: 31px;
  height: 31px;
  margin-right: 12px;
}

.geo__bg .zoom p {
  color: #a4a9b1;
  text-align: center;
  font-size: 11px;
  font-weight: 400;
  line-height: 120%;
}

@media (width >= 1180px) {
  .geo__bg .zoom {
    display: none;
  }

  .geo__bg {
    width: 85%;
    margin: 0 auto;
  }
}

.brands {
  min-height: 100vh;
  height: max-content;
  background-image: url("background_brands_mobile.53eb2287.webp");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding-top: 85px;
  position: relative;
}

@media (width >= 1180px) {
  .brands {
    background-image: url("background_brands.b24cff2e.webp");
    padding-top: 100px;
  }
}

.brands__content {
  padding: 0 0 30px;
}

@media (width >= 1180px) {
  .brands__content {
    padding: 0 60px 60px;
  }
}

.brands__content h2 {
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: 800;
  line-height: 120%;
}

@media (width >= 1180px) {
  .brands__content h2 {
    margin-bottom: 60px;
    font-size: 44px;
  }
}

.brands-switcher {
  width: auto;
  scrollbar-width: none;
  grid-template-columns: 1fr 1fr;
  gap: 35px;
  margin: 0 auto 50px;
  transition: all .25s ease-in-out;
  display: grid;
  position: relative;
  overflow-x: scroll;
}

@media (width >= 1180px) {
  .brands-switcher {
    justify-items: start;
    margin: 0 0 50px;
    display: flex;
  }
}

.brands-switcher__input {
  display: none;
}

.brands-switcher__label {
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.brands-switcher__label.axwin img {
  transform: translateY(25%);
}

.brands-switcher__logo {
  filter: grayscale();
  opacity: .5;
}

.brands-switcher__logo.active {
  filter: none;
  opacity: 1;
}

@media (orientation: portrait) {
  .brands-switcher {
    gap: 20px;
  }
}

.brands__wildsino, .brands__moonwin, .brands__jeetcity, .brands__axwin {
  grid-template-rows: auto 1fr;
  display: grid;
}

@media (width >= 1180px) {
  .brands__wildsino, .brands__moonwin, .brands__jeetcity, .brands__axwin {
    grid-gap: 35px;
    grid-template-columns: 1fr 1fr;
  }
}

.animation {
  text-align: center;
  margin-bottom: 50px;
  position: relative;
}

@media (width >= 1180px) {
  .animation {
    order: 2;
  }
}

.animation .desktop {
  width: 70%;
  animation: 5s infinite top;
}

@media (width >= 1180px) {
  .animation .desktop {
    width: 100%;
  }
}

.animation .phone {
  width: 25%;
  animation: 5s infinite bottom;
  position: absolute;
  bottom: 0;
}

.animation .phone.right {
  right: 10%;
}

@media (width >= 1180px) {
  .animation .phone.right {
    right: 0;
  }
}

.animation .phone.left {
  left: 10%;
}

@media (width >= 1180px) {
  .animation .phone.left {
    left: 0;
  }

  .animation .phone {
    width: 35%;
  }
}

.info .text {
  margin-bottom: 28px;
}

.info .text:first-child {
  display: none;
}

@media (width >= 1180px) {
  .info .text:first-child {
    display: block;
  }
}

.info .text h4 {
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 2px;
  font-size: 14px;
  font-weight: 600;
  line-height: 168%;
}

@media (width >= 1180px) {
  .info .text h4 {
    text-align: start;
    font-size: 14px;
  }
}

.info .text p {
  text-align: center;
  color: #a4a9b1;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 168%;
}

@media (width >= 1180px) {
  .info .text p {
    max-width: 525px;
    text-align: start;
    font-size: 13px;
  }
}

.info .link {
  grid-gap: 12px;
  grid-template-rows: 1fr 1fr;
  justify-items: center;
  display: grid;
}

@media (width >= 1180px) {
  .info .link {
    grid-template-rows: none;
    grid-template-columns: max-content max-content;
    justify-items: start;
    margin: 0;
    padding: 20px 0;
  }
}

.info .link a {
  width: max-content;
}

.info .link .no-show {
  display: none;
}

@keyframes bottom {
  0% {
    transform: translateZ(0)translateY(0);
  }

  50% {
    transform: translateZ(0)translateY(15%);
  }

  100% {
    transform: translateZ(0)translateY(0);
  }
}

@keyframes top {
  0% {
    transform: translateZ(0)translateY(0);
  }

  50% {
    transform: translateZ(0)translateY(-15%);
  }

  100% {
    transform: translateZ(0)translateY(0);
  }
}

.deals {
  min-height: 100vh;
  height: max-content;
  background-image: url("background_deals_mobile.8392ac4f.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding-top: 85px;
  position: relative;
}

@media (width >= 1180px) {
  .deals {
    background-image: url("background_deals.cadc70c8.webp");
    padding-top: 100px;
  }
}

.deals__content {
  padding: 0 0 40px;
}

@media (width >= 1180px) {
  .deals__content {
    padding: 0 30px 0 60px;
  }
}

.deals__content h2 {
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 800;
  line-height: 120%;
}

@media (width >= 1180px) {
  .deals__content h2 {
    font-size: 44px;
  }
}

.deals__content p {
  color: #a4a9b1;
  text-align: center;
  margin-bottom: 50px;
  font-size: 13px;
  font-weight: 400;
  line-height: 168%;
}

@media (width >= 1180px) {
  .deals__content p {
    margin-bottom: 80px;
  }
}

.card {
  width: max-content;
  height: max-content;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background: #ffffff0d;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  display: flex;
  position: relative;
}

@media (width >= 1180px) {
  .card:nth-child(2n) {
    transform: translateY(25%);
  }
}

.card:before {
  content: "";
  height: 11px;
  background-image: url("top_line.50eedc7f.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: -20px;
  left: -15px;
  right: -15px;
}

.card:after {
  content: "";
  height: 22px;
  background-image: url("bottom_line.e3704df2.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  bottom: -30px;
  left: -15px;
  right: -15px;
}

.card__wrapper {
  grid-gap: 60px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  margin-bottom: 66px;
  padding: 30px 40px 60px;
  display: grid;
  overflow-x: auto;
}

.card__wrapper::-webkit-scrollbar {
  display: none;
}

@media (width >= 1180px) {
  .card__wrapper {
    grid-gap: 30px;
    margin-bottom: 150px;
    padding: 30px 10px 80px;
    overflow-x: hidden;
  }
}

.card__info {
  border-top: 1px solid #00000080;
  border-bottom: 1px solid #00000080;
  padding: 26px 6px;
}

.card__info h4 {
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  white-space: nowrap;
  margin-bottom: 4px;
  font-size: 22px;
  font-weight: 500;
  line-height: 120%;
}

.card__info h3 {
  text-align: center;
  text-transform: uppercase;
  color: #fcee20;
  margin-bottom: 26px;
  font-size: 26px;
  font-weight: 800;
  line-height: 120%;
}

.card__info-list {
  grid-gap: 12px;
  grid-template-rows: max-content max-content;
  justify-items: center;
  display: grid;
}

.card__info-list-item {
  color: #fff;
  white-space: nowrap;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
}

.link {
  text-align: center;
  padding-bottom: 100px;
}

@media (width >= 1180px) {
  .link {
    padding-bottom: 110px;
  }
}

.contacts {
  min-height: 100vh;
  height: max-content;
  background-image: url("background_contacts.1d0635f5.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 85px;
  position: relative;
}

@media (width >= 1180px) {
  .contacts {
    background-position: 0;
    padding-top: 120px;
  }
}

.contacts .point {
  width: 27px;
  height: 35px;
  background-image: url("map_point.f0f771b0.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}

.contacts .point__text {
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 120%;
  display: flex;
}

.contacts .point__wrapper {
  grid-gap: 9px;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  display: grid;
  position: absolute;
  bottom: 20vh;
  left: 50%;
  transform: translateX(-50%);
}

@media (width >= 1180px) {
  .contacts .point__wrapper {
    left: 28vw;
  }
}

.contacts .point .circle {
  width: 12px;
  height: 12px;
  background-color: #fcee20;
  border-radius: 50%;
  animation: 5s infinite pulsar;
  position: absolute;
  top: 7px;
  left: 28%;
  transform: translateX(-50%);
}

.contacts .point .elips-first {
  width: 4px;
  height: 4px;
  background: #00000075;
  border-radius: 50%;
  animation: 5s infinite elips;
  position: absolute;
  bottom: -2px;
  left: 43%;
}

.contacts .point .elips-second {
  width: 4px;
  height: 4px;
  background: #0000004d;
  border-radius: 50%;
  animation: 5s infinite elipsS;
  position: absolute;
  bottom: -2px;
  left: 43%;
}

.contacts__content {
  position: relative;
}

@media (width >= 1180px) {
  .contacts__content {
    padding: 0 120px 160px;
  }
}

.contacts__info {
  flex-direction: column;
  display: flex;
}

@media (width >= 1180px) {
  .contacts__info {
    flex-direction: row;
    justify-content: space-between;
  }
}

.contacts__info-links {
  margin-bottom: 30px;
}

@media (width >= 1180px) {
  .contacts__info-links {
    margin: 0;
  }
}

.contacts__info-links h2 {
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: 800;
  line-height: 120%;
}

@media (width >= 1180px) {
  .contacts__info-links h2 {
    text-align: start;
    margin-bottom: 50px;
    font-size: 44px;
  }
}

.contacts__info-links .links__list {
  grid-gap: 20px;
  grid-template-rows: 1fr 1fr 1fr;
  justify-items: center;
  display: grid;
}

@media (width >= 1180px) {
  .contacts__info-links .links__list {
    grid-gap: 30px;
    justify-items: start;
  }
}

.contacts__info-links .links__list-link {
  grid-gap: 12px;
  color: #fff;
  grid-template-columns: max-content max-content;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  line-height: 126%;
  display: grid;
}

.contacts__info-links .links__list-image {
  justify-content: center;
  align-items: center;
  display: flex;
}

.contacts__info-message {
  display: none;
}

@media (width >= 1180px) {
  .contacts__info-message {
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

.contacts__info-message .message-card {
  max-width: 480px;
  width: 100vw;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  text-align: center;
  background: #ffffff08;
  border-radius: 0;
  margin: 0 -20px;
  padding: 32px 20px;
  position: relative;
}

.contacts__info-message .message-card:before {
  content: "";
  height: 17px;
  background-image: url("top_line_contacts.c909084a.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: none;
  position: absolute;
  top: -20px;
  left: 0;
  right: 20%;
}

@media (width >= 1180px) {
  .contacts__info-message .message-card:before {
    display: block;
  }
}

.contacts__info-message .message-card:after {
  content: "";
  height: 36px;
  background-image: url("bottom_line_contacts.bc471793.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: none;
  position: absolute;
  bottom: -36px;
  left: 50%;
  right: 0;
}

@media (width >= 1180px) {
  .contacts__info-message .message-card:after {
    display: block;
  }

  .contacts__info-message .message-card {
    border: 1px solid #ffffff40;
    border-radius: 0 100px;
    padding: 56px 64px;
  }
}

.contacts__info-message .message-card h3 {
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 600;
  line-height: 120%;
}

@media (width >= 1180px) {
  .contacts__info-message .message-card h3 {
    margin-bottom: 50px;
    font-size: 24px;
  }
}

.contacts__info-message .message-input {
  color: #fff;
  box-sizing: border-box;
  width: 100%;
  max-height: 46px;
  resize: none;
  background: #1c1e21;
  border: 1px solid #1c1e21;
  border-radius: 10px;
  margin-bottom: 14px;
  padding: 14px 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 126%;
}

.contacts__info-message .message-input:focus {
  border: 1px solid #a4a9b1;
}

.contacts__info-message .message-input::placeholder {
  color: #62666c;
  font-size: 14px;
  font-weight: 500;
  line-height: 126%;
}

.contacts__info-message .message-textarea {
  color: #fff;
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  background: #1c1e21;
  border: 1px solid #1c1e21;
  border-radius: 10px;
  outline: none;
  margin-bottom: 30px;
  padding: 14px 16px;
  font-family: Popins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 126%;
}

.contacts__info-message .message-textarea:focus {
  border: 1px solid #a4a9b1;
}

.contacts__info-message .message-textarea::placeholder {
  color: #62666c;
  font-family: Popins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 126%;
}

@keyframes pulsar {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes elips {
  0% {
    transform: scaleX(1)scaleY(1);
  }

  50% {
    transform: scaleX(6)scaleY(1.5);
  }

  100% {
    transform: scaleX(1)scaleY(1);
  }
}

@keyframes elipsS {
  0% {
    transform: scaleX(1)scaleY(1);
  }

  50% {
    transform: scaleX(10)scaleY(3);
  }

  100% {
    transform: scaleX(1)scaleY(1);
  }
}

.pagination {
  display: none;
}

@media (width >= 1180px) {
  .pagination {
    z-index: 99;
    display: block;
    position: absolute;
    top: 50vh;
    right: 40px;
    transform: translateY(-50%);
  }

  .pagination:after, .pagination:before {
    content: "";
    width: 1px;
    height: 40px;
    background: #ffffff1a;
    border-radius: 12px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .pagination:after {
    bottom: -65px;
  }

  .pagination:before {
    top: -65px;
  }
}

.pagination__content {
  grid-gap: 20px;
  grid-template-rows: repeat(6, 1fr);
  display: grid;
}

.pagination .dot {
  width: 10px;
  height: 10px;
  box-sizing: border-box;
  cursor: pointer;
  border: 1px solid #fff3;
  border-radius: 50%;
  position: relative;
}

.pagination .dot.active {
  width: 8px;
  height: 8px;
  background: #fff;
  border: 1px solid #fff;
}

.pagination .dot.active:after {
  content: "";
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  filter: drop-shadow(0 0 27px #fcee20) drop-shadow(0 0 12px #fcee20);
  border: 1px solid #fcee20;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-success {
  color: #000;
  border-radius: 12px;
  padding: 14px 30px;
  font-size: 15px;
  font-weight: 500;
  line-height: 126%;
  background: #fcee20 !important;
}

.toast-error {
  color: #000;
  border-radius: 12px;
  padding: 14px 30px;
  font-size: 15px;
  font-weight: 500;
  line-height: 126%;
  background: #fd626b !important;
}

/*# sourceMappingURL=index.c0706434.css.map */
