@import './styles/_reset.scss';
@import './utils/mixins';
@import './utils/vars';

@import './styles/blocks/page.scss';
@import './styles/blocks/wrapper.scss';
@import './styles/blocks/scroll.scss';
@import './styles/blocks/header.scss';
@import './styles/blocks/menu.scss';
@import './styles/blocks/logo.scss';
@import './styles/blocks/buttons.scss';
@import './styles/blocks/primary-button.scss';
@import './styles/blocks/secondary-button.scss';
@import './styles/blocks/sidebar.scss';
@import './styles/blocks/general.scss';
@import './styles/blocks/about.scss';
@import './styles/blocks/geo.scss';
@import './styles/blocks/brands.scss';
@import './styles/blocks/deals.scss';
@import './styles/blocks/contacts.scss';
@import './styles/blocks/pagination.scss';
@import './styles/blocks/toast.scss';
