.toast-success {
  font-weight: 500;
  font-size: 15px;
  line-height: 126%;
  color: $color-black;
  padding: 14px 30px;
  border-radius: 12px;
  background: $color-yellow !important;
}

.toast-error {
  font-weight: 500;
  font-size: 15px;
  line-height: 126%;
  color: $color-black;
  padding: 14px 30px;
  border-radius: 12px;
  background: #fd626b !important;
}