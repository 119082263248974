.buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1px;

  @include desktop {
    grid-gap: 15px;
  }

  & button {
    border-radius: 0;

    @include desktop {
      border-radius: 12px;
    }
  }

  &__container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    max-width: 1440px;
    margin: 0 auto;

    .buttons {
      @include desktop {
        display: none;
      }
    }
  }
}