.menu {
  width: max-content;
  margin: 0;

  @include desktop {
    margin: 0 48px 0 0;
  }

  &-button {
    width: 32px;
    height: 22px;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    background-image: url('./images/icons/menu.svg');
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.3s ease-in-out;

    &.close {
      background-image: url('./images/icons/cross.svg');
    }
  }

  &__content {
    position: fixed;
    right: 0;
    left: 0;
    top: 82px;
    height: 100vh;
    background: rgba(0, 0, 0, 0.92);
    backdrop-filter: blur(5px);
    transform: translateX(100%); 
    transition: all 0.3s ease-in-out;

    &.show {
      transform: translate(0, 0);

      @include  desktop {
        transform: translate(0, -10%);
      }

    }

    @include desktop {
      width: max-content;
      padding: 0;
      left: 30%;
      top: 0;
      height: max-content;
      background: transparent;
      backdrop-filter: blur(0px);
      transform: translate(50%, -10%);
      opacity: 0;
      visibility: hidden;

      &.show {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include desktop {
      flex-direction: row;

      &-item:nth-child(2),
      &-item:nth-child(3),
      &-item:nth-child(4) {
        display: none;
      }
    }

    &-link {
      white-space: nowrap;
      font-weight: 500;
      font-size: 16px;
      line-height: 126%;
      color: $color-white;
      text-transform: uppercase;
      padding: 15px 10px;
    }
  }
}