.wrapper {
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 20px;

  @include desktop {
    padding: 0 40px;
  }

  &--scroll {
    margin: 0 auto;
    max-width: 1440px;
    padding: 0;

    @include desktop {
      padding: 0 40px;
    }

    &.pad {
      @include desktop {
        padding: 0;
      }
    }
  }
}
