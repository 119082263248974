.secondary-button {
  @include text-button;

  align-items: center;
  text-align: center;
  color: $color-white;
  background: rgba(0, 0, 0, 0.18);
  backdrop-filter: blur(5px);
  transition: all 0.3s ease-in-out;

  &:hover,
  &.active {
    background-color: $color-gray;
  }

  &:disabled,
  &[disabled] {
    opacity: 0.5;
  }

  &.second {
    border: 1px solid #62666C;
    border-radius: 12px;
  }

  @include desktop {
    background-color: transparent;
  }
}
