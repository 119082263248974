.brands {
  position: relative;
  min-height: 100vh;
  height: max-content;
  background-image: url('./images/background_brands_mobile.webp');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding-top: 85px;

  @include desktop {
    background-image: url('./images/background_brands.webp');
    padding-top: 100px;
  }

  &__content {
    padding: 0 0 30px;

    @include desktop {
      padding: 0 60px 60px;
    }

    & h2 {
      font-weight: 800;
      font-size: 28px;
      line-height: 120%;
      text-align: center;
      text-transform: uppercase;
      color: $color-white;
      margin-bottom: 30px;

      @include desktop {
        font-size: 44px;
        margin-bottom: 60px;
      }
    }
  }



  &-switcher {
    position: relative;
    width: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 35px;
    margin: 0 auto 50px;
    transition: 0.25s ease-in-out;
    overflow-x: scroll;
    scrollbar-width: none;

    @include desktop {
      margin: 0 0 50px;
      display: flex;
      justify-items: start;
    }

    &__input {
      display: none;
    }

    &__label {
      text-align: center;
      cursor: pointer;
      user-select: none;
      &.axwin img{
        transform: translateY( 25%)
      }
    }

    &__logo {
      filter: grayscale(100%);
      opacity: 0.5;

      &.active {
        filter: none;
        opacity: 1;
      }
    }
  }

  @media (orientation: portrait) {
    &-switcher {
      gap: 20px;
    }
  }
  &__wildsino,
  &__moonwin,
  &__jeetcity,
  &__axwin{
    display: grid;
    grid-template-rows: auto 1fr;

    @include desktop {
      grid-template-columns: 1fr 1fr;
      grid-gap: 35px;
    }
  }
}

.animation {
  position: relative;
  margin-bottom: 50px;
  text-align: center;

  @include desktop {
    order: 2;
  }

  & .desktop {
    width: 70%;
    animation: top;
    animation-duration: 5s;
    animation-iteration-count: infinite;

    @include desktop {
      width: 100%;
    }
  }

  & .phone {
    position: absolute;
    bottom: 0;
    width: 25%;
    animation: bottom;
    animation-duration: 5s;
    animation-iteration-count: infinite;

    &.right {
      right: 10%;

      @include desktop {
        right: 0;
      }
    }

    &.left {
      left: 10%;

      @include desktop {
        left: 0;
      }
    }

    @include desktop {
      width: 35%;
    }
  }
}

.info {
  & .text {
    margin-bottom: 28px;

    &:first-child {
      display: none;
    }

    @include desktop {
      &:first-child {
        display: block;
      }
    }

    & h4 {
      margin-bottom: 2px;
      font-weight: 600;
      font-size: 14px;
      line-height: 168%;
      text-align: center;
      text-transform: uppercase;
      color: $color-white;

      @include desktop {
        font-size: 14px;
        text-align: start;
      }
    }

    & p {
      font-weight: 400;
      font-size: 12px;
      line-height: 168%;
      align-items: center;
      text-align: center;
      color: $color-light-gray;

      @include desktop {
        max-width: 525px;
        font-size: 13px;
        text-align: start;
      }
    }
  }

  & .link {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-gap: 12px;
    justify-items: center;

    @include desktop {
      margin: 0;
      padding: 20px 0;
      grid-template-rows: none;
      grid-template-columns: max-content max-content;
      justify-items: start;
    }

    & a {
      width: max-content;
    }

    & .no-show {
      display: none;
    }
  }
}

@keyframes bottom {
  0% {
    transform: translateZ(0) translateY(0);
  }
  50% {
    transform: translateZ(0) translateY(15%);
  }
  100% {
    transform: translateZ(0) translateY(0);
  }
}

@keyframes top {
  0% {
    transform: translateZ(0) translateY(0);
  }
  50% {
    transform: translateZ(0) translateY(-15%);
  }
  100% {
    transform: translateZ(0) translateY(0);
  }
}
