.geo {
  position: relative;
  min-height: 100vh;
  height: max-content;
  padding-top: 85px;
  background-image: url('./images/background_geo.webp');
  background-repeat: no-repeat;
  background-size: cover;

  @include desktop {
    padding-top: 100px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__info {
    margin-bottom: 30px;

    @include desktop {
      display: none;
    }

    & h2 {
      font-weight: 800;
      font-size: 28px;
      line-height: 120%;
      text-align: center;
      text-transform: uppercase;
      color: $color-white;
      margin-bottom: 20px;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;

    &-item {
      font-weight: 600;
      font-size: 12px;
      line-height: 152%;
      padding: 8px 14px;
      color: $color-white;
      background: rgba(255, 255, 255, 0.12);
      backdrop-filter: blur(2px);
      border-radius: 10px;
      white-space: nowrap;
    }
  }

  &__map {
    display: none;

    @include desktop {
      display: block;
      text-align: center;

      #geo-obj {
        width: 1100px;
        pointer-events: none;
      }
    }
  }

  &__bg {
    position: relative;
    width: 100%;
    height: 40vh;

    @include  desktop {
      display: none;
    }

    .openseadragon-canvas {
      outline: none !important;
    }

    .zoom {
      position: absolute;
      left: 0;
      bottom: 0;
      display: flex;
      align-items: center;

      & img {
        width: 31px;
        height: 31px;
        margin-right: 12px;
      }

      & p {
        font-weight: 400;
        font-size: 11px;
        line-height: 120%;
        color: $color-light-gray;
        text-align: center;
      }

      @include desktop {
        display: none;
      }
    }

    @include desktop {
      margin: 0 auto;
      width: 85%;
    }
  }
}
